import fetch, {RequestInit, BodyInit} from 'node-fetch';
import axios from 'axios';
import {parseCookies} from 'nookies';
import {getCookie} from './helpers/cookie';
import {checkAuth} from './auth';
/* eslint-disable  @typescript-eslint/no-explicit-any */

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

/**
 * API
 */
class Api {
  /**
   * [headers description]
   *
   * @return  {[type]}  [return description]
   */

  accessToken: unknown;
  /**
   * Set initial value for accessToken
   */
  constructor() {
    this.accessToken = '';
  }
  /**
   * Set default headers and Authorization header for api calls
   *
   * @return  {object} headers object
   */
  headers() {
    return Object.assign({}, defaultHeaders, {
      'X-API-KEY': process.env.NEXT_PUBLIC_AUTHORIZATION,
    });
  }
  /**
   * [route description]
   *
   * @param   {string}  route   [route description]
   * @param   {Record<string, string>}  params  [params description]
   *
   * @return  {[type]}          [return description]
   */
  get(route: string, params?: Record<string, string>) {
    return this.xhr(route, params, 'GET');
  }

  /**
   * PATCH API call
   *
   * @param   {string}  route   [route description]
   * @param   {Record<string, string>}  params  [params description]
   *
   * @return  {[type]}          [return description]
   */
  patch(route: string, params?: Record<string, string | boolean>) {
    return this.xhr(route, params, 'PATCH');
  }

  /**
   * [put description]
   *
   * @param   {string}  route   [route description]
   * @param   {Record<string, string>}  params  [params description]
   *
   * @return  {[type]}          [return description]
   */
  put(route: string, params?: Record<string, string>) {
    return this.xhr(route, params, 'PUT');
  }

  /**
   * [post description]
   *
   * @param   {string}  route   [route description]
   * @param   {Record<string, string>}  params  [params description]
   *
   * @return  {[type]}          [return description]
   */
  post(route: string, params?: Record<string, string | boolean>) {
    return this.xhr(route, params, 'POST');
  }

  /**
   * [delete description]
   *
   * @param   {string}  route   [route description]
   * @param   {Record<string, string>}  params  [params description]
   *
   * @return  {[type]}          [return description]
   */
  delete(route: string, params?: Record<string, string>) {
    return this.xhr(route, params, 'DELETE');
  }

  /**
   * [postMultipart description]
   *
   * @param   {string}  route   [route description]
   * @param   {Record<string, string>}  params  [params description]
   *
   * @return  {[type]}          [return description]
   */
  postMultipart(route: string, params: Record<string, string>) {
    return this.xhrMulti(route, params);
  }

  /**
   * [postFormData description]
   *
   * @param   {string}  route     [route description]
   * @param   {FormData}  formData  [formData description]
   *
   * @return  {[type]}            [return description]
   */
  postFormData(route: string, formData: FormData) {
    return this.xhrFormData(route, formData);
  }

  /**
   * [postFormData description]
   *
   * @param   {string | undefined}  token     [firebase token]
   * @param   {boolean}             manager   Should we check the user's manager setting and return the managed user instead
   *
   * @return  {[type]}            [return description]
   */
  async fetchCurrentUser(token: string | undefined, manager = false) {
    const currentUserHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-API-KEY': process.env.NEXT_PUBLIC_AUTHORIZATION,
      Authorization: `Bearer ${token}`,
    };
    const currentUser = await axios({
      method: 'get',
      url: `${process.env.NEXT_PUBLIC_HOST_URL}/users/current${
        manager ? '?manager=true' : ''
      }`,
      headers: currentUserHeaders,
    })
      .then(response => {
        return response;
      })
      .catch(err => {
        return err;
      });
    if (currentUser.status !== 200) {
      throw currentUser.data;
    }

    return currentUser.data;
  }

  /**
   * [postFormData description]
   *
   *
   * @return  {[type]}            [return description]
   */
  async userLogout() {
    const currentUserHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-API-KEY': process.env.NEXT_PUBLIC_AUTHORIZATION,
      Authorization: this.getAuthorizationHeader(),
    };
    const expiredTime = Date.now();
    const currentUser = await axios({
      method: 'get',
      url: `${process.env.NEXT_PUBLIC_CF_LOGOUT_URL}?timestamp=${expiredTime}`,
      headers: currentUserHeaders,
      withCredentials: true,
    })
      .then(response => {
        return response;
      })
      .catch(err => {
        return err;
      });
    if (currentUser.status !== 200) {
      throw currentUser.data;
    }

    return currentUser.data;
  }

  /**
   * [xhr description]
   *
   * @param   {string}  route   [route description]
   * @param   {Record<string, string>}  params  [params description]
   * @param   {string}  verb    [verb description]
   *
   * @return  {[type]}          [return description]
   */
  async xhr(
    route: string,
    params?: Record<string, string | boolean>,
    verb?: string
  ) {
    const options = Object.assign({method: verb});
    options.headers = this.headers();
    options.headers['Authorization'] = this.getAuthorizationHeader();
    params = params ? params : {};

    let query;
    let url;
    if (verb === 'GET') {
      query = this.getQuery(params);
      !query
        ? (url = `${process.env.NEXT_PUBLIC_HOST_URL}${route}`)
        : (url = `${process.env.NEXT_PUBLIC_HOST_URL}${route}?${query}`);
    } else {
      options.body = JSON.stringify(params);
      url = `${process.env.NEXT_PUBLIC_HOST_URL}${route}`;
    }

    let response = await fetch(url, options);
    let data = await response.json();

    // If API request fails on the client side with unauthorized error, refresh the user auth and try again
    if (
      typeof window !== 'undefined' &&
      options.headers['Authorization'] &&
      response.status === 401
    ) {
      const credential = await checkAuth(null);
      if (credential) {
        options.headers['Authorization'] = this.getAuthorizationHeader();
        response = await fetch(url, options);
        data = await response.json();
      }
    }
    if (!response.ok) throw data;

    return data;
  }

  /**
   * [xhrMulti description]
   *
   * @param   {string}  route   [route description]
   * @param   {Record<string, any>}  params  [params description]
   *
   * @return  {[type]}          [return description]
   */
  async xhrMulti(route: string, params?: Record<string, any>) {
    const form = new FormData();

    for (const key in params) {
      if (key === 'files') {
        params[key].forEach((file, i) => {
          form.append(`file[${i}]`, file);
        });
      } else {
        form.append(key, params[key]);
      }
    }

    const body: BodyInit = form as BodyInit;

    const options: RequestInit = {
      method: 'POST',
      headers: {
        Authorization: this.getAuthorizationHeader(),
      },
      body: body,
    };

    const url = `${process.env.NEXT_PUBLIC_HOST_URL}${route}`;

    return fetch(url, options)
      .then(resp => {
        const json = resp.json();
        if (resp.ok) {
          return json;
        }
        return json.then(err => {
          throw err;
        });
      })
      .then((json: unknown) => {
        return json;
      });
  }
  /**
   * [xhrFormData description]
   *
   * @param   {string}  route     [route description]
   * @param   {FormData}  formData  [formData description]
   *
   * @return  {[type]}            [return description]
   */
  async xhrFormData(route: string, formData: FormData) {
    const options: RequestInit = {
      method: 'POST',
      body: formData as BodyInit,
      headers: {
        Authorization: this.getAuthorizationHeader(),
      },
    };

    const url = `${process.env.NEXT_PUBLIC_HOST_URL}${route}`;

    return fetch(url, options)
      .then(resp => {
        const json = resp.json();
        if (resp.ok) {
          return json;
        }
        return json.then(err => {
          throw err;
        });
      })
      .then((json: unknown) => {
        return json;
      });
  }

  /**
   * [getQuery description]
   *
   * @param   {Record<string, string>}  params  [params description]
   *
   * @return  {[type]}          [return description]
   */
  getQuery(params: Record<string, string | boolean>) {
    return Object.keys(params)
      .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
      .join('&');
  }

  /**
   * [getCSRF description]
   *
   * @return  {[type]}  [return description]
   */
  getCSRF() {
    return getCookie('xsrf-token');
  }

  /**
   * [setToken description]
   *
   * @param   {[type]}  value  [value description]
   *
   */
  setToken(value: unknown) {
    this.accessToken = value;
  }

  /**
   * [getAuthorizationHeader description]
   *
   * @return  {[type]}  [return description]
   */
  getAuthorizationHeader() {
    // return saved accessToken if fetching server side
    if (this.accessToken) return `Bearer ${this.accessToken}`;
    // Otherwise look for cookie
    const cookies = parseCookies();
    const token = cookies?.__session ?? '';

    return token ? `Bearer ${token}` : '';
  }
}

export default new Api();
