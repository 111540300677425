import Api from 'library/api';
import types from './types';
import firebase from 'firebase/compat/app';
import {logOutOtherSites} from '@brightlive/shared/helpers/auth';

/**
 * Logout and redirect to home
 *
 * @param   {boolean}  ssoLogout  Whether to log out all other sites logged in via SSO. Default's to true, should only be set to false from the logout page loaded via iframe on logout
 *
 * @return {void}
 */
export const logout = (
  ssoLogout = true
): {type: string; promise: Promise<void>} => ({
  type: types.LOGOUT,
  promise: new Promise((resolve, reject) => {
    try {
      firebase
        .auth()
        .signOut()
        .then(async () => {
          await Api.userLogout();
          if (ssoLogout) {
            await logOutOtherSites();
          }
          resolve();
        });
    } catch (error) {
      reject(error);
    }
  }),
});
/**
 * Fetch the current user
 * @param {boolean} manager Should we check the user's manager setting and return the managed user instead
 * @return {void}
 */
export const getCurrentUser = (
  manager = false
): {
  type: string;
  promise: Promise<unknown>;
} => ({
  type: types.GET_CURRENT_USER,
  promise: Api.get('/users/current', {manager: manager ? 'true' : 'false'}),
});

/**
 * Fetch the current user
 *
 * @return {void}
 */
export const updateCurrentUser = (data: Record<string, string | boolean>) => ({
  type: types.UPDATE_CURRENT_USER,
  data,
});

/**
 * Fetch the current user
 *
 * @return {void}
 */
export const setContinueUrl = (url: string) => ({
  type: types.SET_CONTINUE_URL,
  url,
});

/**
 * Remove a user
 *
 * @return {void}
 */

export const removeUser = (
  id: string
): {
  type: string;
  promise: Promise<unknown>;
} => ({
  type: types.REMOVE_USER,
  promise: Api.delete(`/users/${id}`),
});
