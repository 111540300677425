import axios from 'axios';
import {parseCookies, destroyCookie, setCookie} from 'nookies';
import firebase from 'firebase/compat';
import {NextPageContext} from 'next';

const cookieName = '__session_sites';

/**
 * Get cookie by name
 *
 * @param   {string}  name  name of the cookie
 *
 * @return  {string}        cookie value or '' if not found
 */
const getCookie = (name: string): string => {
  const cookies = parseCookies();
  return cookies[name] ?? '';
};

/**
 * Get all sites currently registered with auth
 *
 * @return  {string[]} string array of sites
 */
const getSites = (): string[] => {
  if (typeof window !== 'undefined') {
    return getCookie(cookieName).split(',');
  }
  return [];
};

/**
 * Check if the current site is included in the auth tracking cookie meaning it is currently logged in with sso
 *
 * @return  {boolean} whether the site is logged in
 */
const isSiteLoggedIn = (): boolean => {
  return getSites().includes(window.location.host);
};

/**
 * Add the current site to the auth tracking cookie indicating that the current site is logged in via sso
 */
export const logInSite = () => {
  if (typeof window !== 'undefined' && !isSiteLoggedIn()) {
    const cookieOptions: {
      path: string;
      domain?: string | undefined;
    } = {
      path: '/',
    };
    if (window.location.hostname !== 'localhost') {
      cookieOptions.domain = '.brightlive.com';
    }
    setCookie(
      null,
      cookieName,
      getCookie(cookieName) + ',' + window.location.host,
      cookieOptions
    );
  }
};

/**
 * Log out all other sites registered in the auth tracking cookie
 *
 */
export const logOutOtherSites = async () => {
  if (typeof window !== 'undefined') {
    const promises: Promise<boolean>[] = [];
    for (const site of getSites()) {
      if (!site || site === window.location.host) {
        continue;
      }
      const promise = new Promise<boolean>(resolve => {
        const iframe = document.createElement('iframe');
        iframe.onload = () => {
          resolve(true);
        };
        iframe.style.display = 'none';
        iframe.src = `${window.location.protocol}//${site}/client-logout`;
        window.document.body.appendChild(iframe);
      });
      promises.push(promise);
      // Load logout pages for all sites logged in via SSO using invisible iframes appended to the body
    }
    // Wait for all logout windows to load then give firebase 1 second to finish it's logouts on the client of the iframe pages
    if (promises.length > 0) {
      await Promise.all(promises);
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
    const cookieOptions: {
      path: string;
      domain?: string | undefined;
    } = {
      path: '/',
    };
    if (window.location.hostname !== 'localhost') {
      cookieOptions.domain = '.brightlive.com';
    }
    setCookie(null, cookieName, '', cookieOptions);
  }
};

/**
 * Clear a corrupted session if an exception is encountered
 */
export const endSession = (
  firebaseAuth: firebase.auth.Auth | null = null,
  ctx: NextPageContext | null = null,
  redirect = false
) => {
  destroyCookie(null, '__session');
  destroyCookie(null, 'cssc');
  if (redirect && ctx) {
    ctx.res?.writeHead(302, {Location: '/'});
    ctx.res?.end();
  }
};

/**
 * Check to see if the current user is logged in
 *
 * @param   {firebase.auth.Auth}  firebaseAuth  Firebase auth reference for the given site
 * @param   {NextPageContext | null}  ctx  Next page context used to parse cookies from the request on the server side required for server site requests only
 *
 * @return  {Promise<firebase.auth.UserCredential | null>}  User credential or null if the user isn't logged in
 */
export const checkUserAuth = async (
  firebaseAuth: firebase.auth.Auth,
  ctx: NextPageContext | null = null
): Promise<firebase.auth.UserCredential | null> => {
  const isServer = typeof window === 'undefined';
  const AUTH_CHECK_URL = process.env.NEXT_PUBLIC_CF_AUTH_CHECK_URL;
  const headers: {
    Accept: string;
    'Content-Type': string;
    cookie?: string | undefined;
  } = {
    Accept: 'application/json',
    'Content-Type': 'text/plain',
  };
  let token: string | undefined = '';
  // necessary to set cookie server side to allow when user hard refreshes otherwise cookie is lost on the server request
  if (isServer) {
    const cookies = parseCookies(ctx);
    headers.cookie = `__session=${cookies.__session}`;
  } else {
    // It is very important to ot run this on the server since the server runs with an admin context. Only run this on the client!
    token = await firebaseAuth.currentUser?.getIdToken();
  }
  const response = await axios({
    method: 'post',
    url: AUTH_CHECK_URL + (token ? '?refresh=true' : ''),
    headers,
    withCredentials: true,
    data: token,
  })
    .then(res => {
      return res.data;
    })
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .catch(err => {
      return 'unauthorized';
    });

  if (response !== 'unauthorized') {
    try {
      logInSite();
      const userCredential = await firebaseAuth.signInWithCustomToken(response);
      return userCredential;
    } catch (err) {
      console.log('Firebase error: ', err);
      endSession();
      return null;
    }
  }
  return null;
};
