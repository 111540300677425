import keyMirror from 'keymirror';

const types = keyMirror({
  LOGOUT: null,
  GET_CURRENT_USER: null,
  UPDATE_CURRENT_USER: null,
  SET_CONTINUE_URL: null,
  REMOVE_USER: null,
});

export default types;
